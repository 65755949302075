<template>
  <div>
    <el-card style="width: 90%; margin: 10px;;text-align: left">
      <el-form ref="form" :model="form" label-width="110px" :rules="rules">
        <el-form-item style="text-align: center" label-width="0">
        </el-form-item>
<!--        <el-form-item label="吨价" prop="texture_ton">
          <el-input v-model="form.texture_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>-->
        <el-form-item label="pet吨价" prop="pet_ton" style="width:40%;float: left;">
          <el-input v-model="form.pet_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="pa吨价" prop="pa_ton" style="width:40%;float: left">
          <el-input v-model="form.pa_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="bopp吨价" prop="bopp_ton" style="width:40%;float: left">
          <el-input v-model="form.bopp_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="cpp吨价" prop="cpp_ton" style="width:40%;float: left">
          <el-input v-model="form.cpp_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="pe吨价" prop="pe_ton" style="width:40%;float: left">
          <el-input v-model="form.pe_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="水pe吨价" prop="spe_ton" style="width:40%;float: left">
          <el-input v-model="form.spe_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="cpe吨价" prop="cpe_ton" style="width:40%;float: left">
          <el-input v-model="form.cpe_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="rcpp吨价" prop="rcpp_ton" style="width:40%;float: left">
          <el-input v-model="form.rcpp_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="petac吨价" prop="petac_ton" style="width:40%;float: left">
          <el-input v-model="form.petac_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="乳白pe吨价" prop="rbpe_ton" style="width:40%;float: left">
          <el-input v-model="form.rbpe_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="cppal吨价" prop="cppal_ton" style="width:40%;float: left">
          <el-input v-model="form.cppal_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="ac吨价" prop="ac_ton" style="width:40%;">
          <el-input v-model="form.ac_ton" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>

        <el-form-item label="复合参数" prop="composite" style="width:80%;">
          <el-input v-model="form.composite" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="制袋-有拉链" prop="bag_zipper" style="width:80%;">
          <el-input v-model="form.bag_zipper" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="制袋-无拉链" prop="bag_zipper_no" style="width:80%;">
          <el-input v-model="form.bag_zipper_no" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="废品率" prop="waste" style="width:80%;">
          <el-input v-model="form.waste" placeholder="请输入数字(限数字)" type="number"></el-input>
        </el-form-item>
        <el-form-item label="利润" prop="profit" style="width:80%;">
          <el-input v-model="form.profit" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="税点" prop="tax" style="width:80%;">
          <el-input v-model="form.tax" placeholder="请输入数字(限数字)" type="number" ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="update">保存</el-button>
      </div>
    </el-card>

  </div>
</template>

<script>
import request from "../utils/request";

export default {
  name: "Proportion",
  data() {
    return {
      form: {},
      user:{},
      dataId:0,
      rules: {
        /*texture_ton: [{required: true, message: '请输入吨价', trigger: 'blur',}],*/
        pet_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        pa_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        bopp_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        cpp_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        pe_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        spe_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        cpe_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        rcpp_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        petac_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        rbpe_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        cppal_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        ac_ton: [{required: true, message: '请输入', trigger: 'blur',}],
        composite: [{required: true, message: '请输入复合', trigger: 'blur',}],
        bag_zipper: [{required: true, message: '请输入制袋-有拉链', trigger: 'blur',}],
        bag_zipper_no: [{required: true, message: '请输入制袋-有拉链', trigger: 'blur',}],
        waste: [{required: true, message: '请输入废品率', trigger: 'blur',}],
        profit: [{required: true, message: '请输入利润', trigger: 'blur',}],
        tax: [{required: true, message: '请输入税点', trigger: 'blur',}],
      },
    }
  },
  created() {
    let userStr = sessionStorage.getItem("user") || "{}"
    this.user = JSON.parse(userStr);
    if(this.user.id){
      //请求服务端 确认当前登录用户的合法信息
      request.post("/user/detail.php",{
        dataId:this.user.id,
      }).then(res => {
        if (res.code === '0') {
          this.user = res.data
        }
      })
      this.load()
    }

  },
  methods: {
    load(){
      request.post("/proportion/detail.php").then(res => {
        this.form = res.data[0];
      })
    },
    update() {
      /*if (!this.form.texture_ton || this.form.texture_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入吨价！'
        })
        return;
      }*/
      if (!this.form.pet_ton || this.form.pet_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入pet吨价！'
        })
        return;
      }
      if (!this.form.pa_ton || this.form.pa_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入pa吨价！'
        })
        return;
      }
      if (!this.form.bopp_ton || this.form.bopp_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入bopp吨价！'
        })
        return;
      }
      if (!this.form.cpp_ton || this.form.cpp_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入cpp吨价！'
        })
        return;
      }
      if (!this.form.pe_ton || this.form.pe_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入pe吨价！'
        })
        return;
      }
      if (!this.form.spe_ton || this.form.spe_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入水pe吨价！'
        })
        return;
      }
      if (!this.form.cpe_ton || this.form.cpe_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入cpe吨价！'
        })
        return;
      }
      if (!this.form.rcpp_ton || this.form.rcpp_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入rcpp吨价！'
        })
        return;
      }
      if (!this.form.petac_ton || this.form.petac_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入petac吨价！'
        })
        return;
      }
      if (!this.form.rbpe_ton || this.form.rbpe_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入乳白pe吨价！'
        })
        return;
      }
      if (!this.form.cppal_ton || this.form.cppal_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入cppal吨价！'
        })
        return;
      }
      if (!this.form.ac_ton || this.form.ac_ton <=0) {
        this.$message({
          type: "error",
          message: '请输入ac吨价！'
        })
        return;
      }

      if (!this.form.composite || this.form.composite <=0) {
        this.$message({
          type: "error",
          message: '请输入复合！'
        })
        return;
      }
      if (!this.form.bag_zipper || this.form.bag_zipper <=0) {
        this.$message({
          type: "error",
          message: '请输入制袋-有拉链！'
        })
        return;
      }
      if (!this.form.bag_zipper_no || this.form.bag_zipper_no <=0) {
        this.$message({
          type: "error",
          message: '请输入制袋-有拉链！'
        })
        return;
      }
      if (!this.form.waste || this.form.waste <=0) {
        this.$message({
          type: "error",
          message: '请输入废品率！'
        })
        return;
      }
      if (!this.form.profit || this.form.profit <=0) {
        this.$message({
          type: "error",
          message: '请输入利润！'
        })
        return;
      }
      if (!this.form.tax || this.form.tax <=0) {
        this.$message({
          type: "error",
          message: '请输入税点！'
        })
        return;
      }
      if(this.form.id){ //判断是否拥有用户ID 有则更新
        request.post("/proportion/update.php",this.form).then(res => {
          if(res.code == '0'){
            this.$message({
              type: "success",
              message: res.msg
            })
          }else{
            this.$message({
              type: "error",
              message: res.msg
            })
          }
        })
      }else{ //新增
        request.post("/proportion/save.php",this.form).then(res => {
          if(res.code === '0'){
            this.$message({
              type: "success",
              message: res.msg
            })
          }else{
            this.$message({
              type: "error",
              message: res.msg
            })
          }
        })
      }
    }
  }
}
</script>

